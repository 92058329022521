// @flow

import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import {
  HomeHero,
  Layout,
  LinkedButton,
  MarkdownPs,
  Section,
  SectionScreenshot,
  SectionTestimonial,
  SectionsPartners,
  TextHeading,
} from '../components';
import { Dimen } from '../constants';
import { unwrapConnection } from '../util';

import type { PageHomeQuery } from '../__generated__/PageHomeQuery';

type Props = $ReadOnly<{|
  data: PageHomeQuery,
|}>;

const imgWidth = 330;
const imgHeight = 330;

const IndexPage = ({
  data: {
    homepage,
    testimonial,
    allDatoCmsSectionScreenshot,
    allDatoCmsPartner,
  },
}: Props) => {
  if (!homepage) throw new Error('Homepage missing from CMS');
  if (!allDatoCmsPartner) throw new Error('Missing allDatoCmsPartner');

  const unwrappedScreenshotSections = unwrapConnection(
    allDatoCmsSectionScreenshot
  );
  const screenshotSections = {};
  [1, 2, 3].forEach(n => {
    const key = `home_${n}`;
    screenshotSections[key] = unwrappedScreenshotSections.find(
      b => b.identifier === key
    );
  });

  const image1Url = homepage.bottomImage1?.url;
  const image1Src =
    image1Url && `${image1Url}?fit=max&w=${imgWidth}&h=${imgHeight}`;

  const image2Url = homepage.bottomImage2?.url;
  const image2Src =
    image2Url && `${image2Url}?fit=max&w=${imgWidth}&h=${imgHeight}`;

  const image3Url = homepage.bottomImage3?.url;
  const image3Src =
    image3Url && `${image3Url}?fit=max&w=${imgWidth}&h=${imgHeight}`;

  return (
    <Layout>
      <HelmetDatoCms seo={homepage.seoMetaTags} />
      <HomeHero
        title={homepage.title}
        subtitle={homepage.subtitle}
        ctaButtonLabel={homepage.ctaButtonLabel}
        ctaButtonLink={homepage.ctaButtonLink}
        secondaryButtonLabel={homepage.secondaryButtonLabel}
        secondaryButtonLink={homepage.secondaryButtonLink}
      />
      {testimonial && (
        <SectionTestimonial testimonial={testimonial} backgroundColor="red" />
      )}
      {screenshotSections.home_1 && (
        <SectionScreenshot
          screenshot={screenshotSections.home_1}
          imageSrc="/img/screenshots/homepage_1.png"
          effectSrc="/img/screenshots/effect_1.svg"
          picAfter
          backgroundColor="grey"
        />
      )}
      {screenshotSections.home_2 && (
        <SectionScreenshot
          screenshot={screenshotSections.home_2}
          imageSrc="/img/screenshots/homepage_2.png"
          effectSrc="/img/screenshots/effect_2.svg"
        />
      )}
      {screenshotSections.home_3 && (
        <SectionScreenshot
          screenshot={screenshotSections.home_3}
          fullImageSrc="/img/screenshots/homepage_3.png"
          picAfter
          backgroundColor="grey"
        />
      )}
      <StyledLeadingText>
        <TextHeading level={3} tag="h1">
          {homepage.bottomTitle}
        </TextHeading>
        {homepage.bottomSubtitle && (
          <MarkdownPs html={homepage.bottomSubtitle} />
        )}
        <StyledImages>
          <img src={image1Src} />
          <img src={image2Src} />
          <img src={image3Src} />
        </StyledImages>
        <LinkedButton
          label={homepage.bottomButtonLabel}
          link={homepage.bottomButtonLink}
        />
      </StyledLeadingText>
      <SectionsPartners partnersConnection={allDatoCmsPartner} />
    </Layout>
  );
};
export default IndexPage;

export const query = graphql`
  query PageHomeQuery {
    homepage: datoCmsHomepage {
      title
      subtitle
      ctaButtonLabel
      ctaButtonLink
      secondaryButtonLabel
      secondaryButtonLink
      bottomTitle
      bottomSubtitle
      bottomButtonLabel
      bottomButtonLink
      bottomImage1 {
        url
      }
      bottomImage2 {
        url
      }
      bottomImage3 {
        url
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    testimonial: datoCmsTestimonial(identifier: { eq: "home_1" }) {
      ...SectionTestimonial_testimonial
    }

    allDatoCmsSectionScreenshot(filter: { identifier: { regex: "/^home_/" } }) {
      edges {
        node {
          identifier
          ...SectionScreenshot_screenshot
        }
      }
    }

    allDatoCmsPartner {
      ...SectionsPartners_partnersConnection
    }
  }
`;

const StyledLeadingText = styled(Section)`
  padding: ${rem(Dimen.spacing * 2)} 0;
  text-align: center;
`;

const StyledImages = styled('div')`
  display: flex;
  justify-content: center;
  > img {
    display: block;
    flex: 1;
    margin: ${rem(Dimen.spacing / 2)};
    max-width: ${rem(imgWidth)};
    max-height: ${rem(imgHeight)};
  }

  @media (max-width: 720px) {
    flex-direction: column;
    align-items: center;

    > img {
      margin-bottom: ${rem(Dimen.spacing)};
    }
  }
`;
